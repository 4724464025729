// Don't look too close. This code is quite ugly!


import "babel-polyfill";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { Tezos } from "@taquito/taquito";

const contractAddress = "KT1Hkg5qeNhfwpKW4fXvq7HGZB9z2EnmCCA9";
const walletAddressA = "tz1bsuoiv7rBf6ysfT3pEHT8Qp4xyaWtF1v8";
const walletAddressB = "tz1PC53FtwernNVQi889TaJHZrVo9G8LuJnA";
let userAddress, userBalance, contractInstance;

window.onload = () => {
  document.getElementById("init-wallet").onclick = initWallet;
  document.getElementById("init-wallet-a-button").onclick = initWallet;
  document.getElementById("init-wallet-b-button").onclick = initWallet;
  document.getElementById("burn-a-button").onclick = burnA;
  document.getElementById("burn-b-button").onclick = burnB;
};

const updateInnerText = (id, text) =>
  (document.getElementById(id).innerText = text);

const showToast = msg => {
  const toast = document.getElementById("toast");
  toast.textContent = msg;
  setTimeout(() => {
    toast.className = "show";
    setTimeout(() => {
      toast.className = toast.className.replace("show", "");
    }, 3000);
  }, 3000);
};


const initNFTs = async () => {
  try {
    Tezos.setProvider({ rpc: 'https://mainnet-tezos.giganode.io/'});

    // setting up network
    const network = {
      type: "mainnet",
    };

    // NFT A
    const nftaBalance = await Tezos.tz.getBalance(walletAddressA)
    let nftaBalanceFormatted = await nftaBalance.toNumber()
    await console.log('Balance NFT A 2 ; ',  nftaBalanceFormatted);

    // NFT B
    const nftbBalance = await Tezos.tz.getBalance(walletAddressB)
    const nftbBalanceFormatted = await nftbBalance.toNumber()
    await console.log('Balance NFT B 2 ; ',  nftbBalanceFormatted);

    // Total calculations
    let totalDonated = await (nftaBalanceFormatted + nftbBalanceFormatted);
    console.log('Total donated; ', totalDonated);

    let nftaShare = (nftaBalance * 100) / totalDonated;
    let nftbShare = (nftbBalance * 100) / totalDonated;

    console.log('Share NFT A ; ',  nftaShare) , '%';
    console.log('Share NFT B ; ',  nftbShare) , '%';


   if (totalDonated > 1 ) {

      if (nftbShare > 1) {
        for (var i = 0; i < Math.round(nftbShare); i+=10) {

          var elem = document.createElement("img");
          elem.setAttribute("src", "https://www.yanngraf.com/nftdeathmatch/fire1.gif");
          elem.setAttribute("height", "40");
          elem.setAttribute("width", "40");
          elem.setAttribute("alt", "fire");
          elem.setAttribute("class", "inline-block");

          console.log('Share NFT A ; ',  nftaShare) , '%';
          document.getElementById("fire-a").appendChild(elem);
        }
      }

      if (nftaShare > 1) {
        for (i = 0; i < Math.round(nftaShare); i+=10) {

          var elemb = document.createElement("img");
          elemb.setAttribute("src", "https://www.yanngraf.com/nftdeathmatch/fire1.gif");
          elemb.setAttribute("height", "40");
          elemb.setAttribute("width", "40");
          elemb.setAttribute("alt", "fire");
          elemb.setAttribute("class", "inline-block");

          console.log('Share NFT B ; ',  nftaShare) , '%';
          document.getElementById("fire-b").appendChild(elemb);
        }
      }
    }

  } catch (error) {
    console.log(error);
  }
};



const initWallet = async () => {
  try {
    Tezos.setProvider({ rpc: 'https://mainnet-tezos.giganode.io/'});
 
    // creating new Beacon wallet instance
    const options = {
      name: "NFT Deathmatch",
      eventHandlers: {
        PERMISSION_REQUEST_SUCCESS: {
          handler: async data => {
            console.log("Wallet is connected:", data);
          },
        },
        OPERATION_REQUEST_SENT: {
          handler: async data => {
            console.log("Request sent:", data);
          },
        },
        OPERATION_REQUEST_SUCCESS: {
          handler: async data => {
            console.log("Request successful:", data);
            showToast("Request successful!");
          },
        },
        OPERATION_REQUEST_ERROR: {
          handler: async data => {
            console.log("Request error:", data);
            showToast("Request error!");
          },
        },
      },
    };
    const wallet = new BeaconWallet(options);
    // setting up network
    const network = {
      type: "mainnet",
    };
    // requesting permissions on selected network
    await wallet.requestPermissions({ network });
    // setting Beacon wallet as wallet provider for Taquito
    Tezos.setWalletProvider(wallet);
    // getting user's address
    userAddress = wallet.permissions.address;
    // getting user's balance on Mainnet
    userBalance = await Tezos.tz.getBalance(userAddress);
    // getting info from smart contract
    contractInstance = await Tezos.wallet.at(contractAddress);
    // hides button
    document.getElementById("connection").classList.add("hidden");
    // shows and populates contract interface
    document.getElementById("interface").style.display = "block";
    updateInnerText("user-address", userAddress);

    // Show Burn buttons
    document.getElementById("burn-a-button").classList.remove("hidden");
    document.getElementById("burn-b-button").classList.remove("hidden");

    // Hide Connect wallet buttons
    document.getElementById("init-wallet-a-button").classList.add("hidden");
    document.getElementById("init-wallet-b-button").classList.add("hidden");
    
  } catch (error) {
    console.log(error);
  }
};

// BUUUURRRNNNNNNN
// Burning
const burnA = async () => {
  document.getElementById("burn-a-button").disabled = true;
  document.getElementById("loader").style.display = "block";
  try {
    const op = await Tezos.wallet.transfer({ to: walletAddressB, amount: 0.1 }).send();
  } catch (error) {
    console.log(error);
  } finally {
    document.getElementById("burn-a-button").disabled = false;
    document.getElementById("loader").style.display = "none";
  }
};


const burnB = async () => {
  document.getElementById("burn-b-button").disabled = true;
  document.getElementById("loader").style.display = "block";
  try {
    const op = await Tezos.wallet.transfer({ to: walletAddressA, amount: 0.1 }).send();
  } catch (error) {
    console.log(error);
  } finally {
    document.getElementById("burn-b-button").disabled = false;
    document.getElementById("loader").style.display = "none";
  }
};


// countdown from https://codepen.io/AllThingsSmitty/pen/JJavZN 
(function () {
  const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

  let matchEnd = "May 3, 2021 22:00:00",
      countDown = new Date(matchEnd).getTime(),
      x = setInterval(function() {    

        let now = new Date().getTime(),
            distance = countDown - now;

        document.getElementById("days").innerText = Math.floor(distance / (day)),
          document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
          document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)),
          document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);

        //do something later when date is reached
        if (distance < 0) {
          let headline = document.getElementById("headline"),
              countdown = document.getElementById("countdown"),
              content = document.getElementById("content");

          headline.innerText = "It's my birthday!";
          countdown.style.display = "none";
          content.style.display = "block";

          clearInterval(x);
        }
        //seconds
      }, 0)
  }());

document.addEventListener("DOMContentLoaded", function(){
  initNFTs();
});